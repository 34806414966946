import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Box, Label, Input, Textarea, Button, Message, Spinner, Text} from 'theme-ui'

const PagePrivacidad = props => {

  return (
    <Layout {...props}>
      <Seo title='Privacidad' />
      <Divider />
      
     
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Política de Privacidad de ANSES Blog'
          subheader='
          
          Fecha de entrada en vigencia: 20/09/2021
          <br><br>
          En ANSES Blog, respetamos tu privacidad y estamos comprometidos a proteger la información personal que puedas proporcionarnos. Esta Política de Privacidad describe cómo recopilamos, utilizamos y protegemos tu información cuando visitas nuestro sitio web, te suscribes a nuestros servicios, o interactúas con nuestros contenidos. Además, explicamos cómo trabajamos con Google AdSense y garantizamos que no venderemos ni comercializaremos tus datos personales.
          
          Por favor, lee detenidamente esta Política de Privacidad para comprender cómo manejamos tus datos personales. Al utilizar nuestro sitio web, aceptas los términos y condiciones descritos en esta política.
          <br><br>
          <b>1. Información que Recopilamos</b>
          <br>
          Información Personal: Recopilamos información personal que tú decidas proporcionarnos voluntariamente, como tu nombre, dirección de correo electrónico, y cualquier otra información que elijas compartir al suscribirte a nuestro boletín informativo o al participar en encuestas u otras interacciones en nuestro sitio web.
          
          Información de Uso: Recopilamos información sobre cómo utilizas nuestro sitio web, incluyendo la dirección IP de tu dispositivo, las páginas que visitas, el tiempo que pasas en nuestro sitio, y otros datos similares.
          
          Cookies y Tecnologías Similares: Utilizamos cookies y tecnologías similares para recopilar información sobre tu navegación en nuestro sitio web. Las cookies son pequeños archivos de datos que se almacenan en tu dispositivo y nos permiten mejorar tu experiencia en nuestro sitio.
          <br><br>
          <b>2. Uso de la Información</b>
          <br>
          Utilizamos la información recopilada para los siguientes propósitos:
          
          a. Brindarte Contenido Personalizado: Utilizamos la información para personalizar el contenido que te mostramos y mejorar tu experiencia en nuestro sitio web.
          
          b. Comunicación Contigo: Utilizamos tu dirección de correo electrónico para enviarte boletines informativos, actualizaciones y noticias relevantes relacionadas con ANSES Blog, siempre que hayas dado tu consentimiento para recibirlos.
          
          c. Mejorar Nuestros Servicios: Analizamos la información recopilada para comprender cómo nuestros usuarios interactúan con nuestro sitio web y cómo podemos mejorarlo.
          <br><br>
          <b>3. Compartir Información con Terceros</b>
          <br>
          ANSES Blog trabaja con Google AdSense para mostrar anuncios en nuestro sitio web. Google AdSense utiliza cookies para proporcionar anuncios personalizados basados en tu historial de navegación. Google AdSense no tiene acceso a tu información personal.
          <br><br>
          <b>4. Seguridad de la Información</b>
          <br>
          Tomamos medidas razonables para proteger la información personal que recopilamos. Sin embargo, ninguna transmisión de datos por Internet o almacenamiento electrónico es completamente segura. Por lo tanto, no podemos garantizar la seguridad absoluta de tus datos.
          <br><br>
          <b>5. Retención de Datos</b>
          <br>
          Mantenemos tus datos personales durante el tiempo necesario para cumplir con los fines descritos en esta Política de Privacidad, a menos que se requiera un período de retención más largo por razones legales.
          <br><br>
          <b> 6. Tus Derechos y Elecciones</b>
          <br>
          Puedes optar por no recibir comunicaciones de ANSES Blog en cualquier momento al darte de baja de nuestro boletín informativo. Además, puedes configurar tu navegador para que bloquee cookies o te notifique cuando se envíen cookies.
          <br><br>
          <b>7. Cambios en esta Política de Privacidad</b>
          <br>
          ANSES Blog se reserva el derecho de actualizar o modificar esta Política de Privacidad en cualquier momento. Cualquier cambio significativo se comunicará en nuestro sitio web. Te recomendamos revisar periódicamente esta política para estar informado sobre cómo protegemos tu privacidad.
          <br><br>
          <b>8. Contacto</b>
          <br>
          Si tienes preguntas o comentarios sobre esta Política de Privacidad, o si deseas ejercer tus derechos relacionados con tus datos personales, por favor contáctanos a través de [dirección de correo electrónico de contacto].
          
          Gracias por confiar en ANSES Blog. Estamos comprometidos en proteger tu privacidad y brindarte información útil sobre ANSES Argentina sin comprometer tus datos personales.

          
          '
        />
      </Stack>

    </Layout>
  )
}

export default PagePrivacidad
